<template>
  <BaseField
    ref="field"
    v-bind="$attrs"
    v-on="$listeners"
    @change="_change"
    @iconClick="clear"
    :value="value"
    :icon="icon"
    :errors="errors"
  >
  </BaseField>
</template>

<script>
import close from '../../img/baseline-close-24px.svg';

import {
  addError,
  errorFormatter,
  resetValidation,
  validate,
  validates,
  validationData,
  validationProps,
} from 'apps/public/lib/validation';

import BaseField from './base-field.vue';

export default {
  name: 'Field',
  components: { BaseField },
  inheritAttrs: false,
  props: {
    value: {},
    ...validationProps,
  },
  data() {
    return {
      icons: {
        clear: {
          iconSVG: close,
          iconTitle: 'Clear',
          iconSmall: true,
        },
      },
      ...validationData(this),
    };
  },
  computed: {
    icon() {
      if (!this.errors.length) {
        if (!this.value) return;

        return this.icons.clear;
      }

      return this.errors[0];
    },
  },
  methods: {
    clear() {
      this.$refs.field.update('');
    },
    async _change(val) {
      this.resetValidation();
    },
    addError,
    errorFormatter,
    resetValidation,
    validate,
    validates,
  },
};
</script>

<style lang="scss">
@use 'sass:math';

// See ~common/styles/field.scss
</style>
