import { render, staticRenderFns } from "./forgot-password.vue?vue&type=template&id=024fdb40&"
import script from "./forgot-password.vue?vue&type=script&lang=js&"
export * from "./forgot-password.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/Frontend-old/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('024fdb40')) {
      api.createRecord('024fdb40', component.options)
    } else {
      api.reload('024fdb40', component.options)
    }
    module.hot.accept("./forgot-password.vue?vue&type=template&id=024fdb40&", function () {
      api.rerender('024fdb40', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "public/pages/account/forgot-password.vue"
export default component.exports