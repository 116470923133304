var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "form form--narrow" }, [
    _c("h2", { staticClass: "form__heading" }, [_vm._v("Forgot Password?")]),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { action: "#" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.sendInstructions()
          },
        },
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form__group" },
          [
            _c("Field", {
              ref: "email",
              attrs: {
                type: "email",
                name: "email",
                label: "E-mail",
                validation: _vm.emailValidation,
              },
              model: {
                value: _vm.forgotPasswordData.email,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.forgotPasswordData,
                    "email",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "forgotPasswordData.email",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.forgotPasswordErrors.resetFailed
          ? _c("div", { staticClass: "form__group form__group--message" }, [
              _c("p", { staticClass: "form__message form__message--error" }, [
                _c("span", {
                  staticClass: "form__message-icon",
                  domProps: { innerHTML: _vm._s(_vm.svg.error) },
                }),
                _vm._v("\n        No account exists with this email!\n      "),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form__button-group form__button-group--right" },
          [
            _c(
              "router-link",
              {
                staticClass: "link link--button form__link",
                attrs: {
                  event: _vm.mode == "popup" ? "" : "click",
                  to: { name: "login" },
                },
                nativeOn: {
                  click: function ($event) {
                    return (function () {
                      return _vm.mode == "popup"
                        ? _vm.$parent.open("logIn")
                        : ""
                    })($event)
                  },
                },
              },
              [_vm._v("\n        Take me back to log in\n      ")]
            ),
            _vm._v(" "),
            _c("fs-button-main", { attrs: { loading: _vm.loading } }, [
              _vm._v("Send e-mail"),
            ]),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "form__group form__group--message form__group--center" },
      [
        _c("p", { staticClass: "form__subheading" }, [
          _vm._v("No worries. Enter your email and we'll send you a link."),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }