<template>
  <section class="form form--narrow">
    <h2 class="form__heading">Forgot Password?</h2>
    <form action="#" @submit.prevent="sendInstructions()">
      <div class="form__group form__group--message form__group--center">
        <p class="form__subheading">No worries. Enter your email and we'll send you a link.</p>
      </div>
      <div class="form__group">
        <Field
          type="email"
          name="email"
          ref="email"
          label="E-mail"
          v-model.trim="forgotPasswordData.email"
          :validation="emailValidation"
        />
      </div>
      <div class="form__group form__group--message" v-if="forgotPasswordErrors.resetFailed">
        <p class="form__message form__message--error">
          <span class="form__message-icon" v-html="svg.error"></span>
          No account exists with this email!
        </p>
      </div>
      <div class="form__button-group form__button-group--right">
        <router-link
          class="link link--button form__link"
          :event="mode == 'popup' ? '' : 'click'"
          @click.native="() => (mode == 'popup' ? $parent.open('logIn') : '')"
          :to="{ name: 'login' }"
        >
          Take me back to log in
        </router-link>
        <fs-button-main :loading="loading">Send e-mail</fs-button-main>
      </div>
    </form>
  </section>
</template>

<script>
import Field from './../components/field.vue';
import FsButtonMain from '@components/buttons/fs-button-main.vue';

import error from '../../img/baseline-error-24px.svg';

export default {
  load({ store, route }) {
    store.head.title = `FindSourcing – Forgot Password`;
  },
  components: { Field, FsButtonMain },
  props: ['mode'],
  data() {
    return {
      loading: false,
      svg: {
        error,
      },
      forgotPasswordData: {
        email: '',
      },
      forgotPasswordErrors: {
        resetFailed: false,
      },
    };
  },
  methods: {
    emailValidation(val) {
      if (val === '') return 'E-mail is required';
    },
    sendInstructions() {
      this.loading = true;
      this.forgotPasswordErrors.resetFailed = false;

      this.$refs.email
        .validates()
        .then((validates) => {
          if (!validates) {
            this.loading = false;
            return;
          }

          this.$store.user
            .forgotPassword(this.forgotPasswordData)
            .then(({ status, user }) => {
              this.loading = false;

              if (status === 1) {
                this.forgotPasswordErrors.resetFailed = true;
                return;
              }

              this.forgotPasswordData.email = '';
              if (this.$parent.$options.name === 'popover-container') this.$parent.close('forgotPassword');
              if (this.mode != 'popup' && this.$router.returnUrl !== '/account/forgotPassword') {
                this.$router.push(this.$router.returnUrl);
              }
              this.$notify({
                type: 'success',
                title: `A link to reset your password has been sent to ${user.email}`,
              });
            })
            .catch((error) => {
              throw new Error(error);
            });
        })
        .catch((error) => {
          this.forgotPasswordErrors.resetFailed = true;
          this.loading = false;
        });
    },
  },
  watch: {
    // Hide errors when email is changed
    'forgotPasswordData.email': function () {
      if (this.forgotPasswordErrors.resetFailed) {
        this.forgotPasswordErrors.resetFailed = false;
      }
    },
  },
  mounted() {
    // Temp hack for main padding, only when in page mode (not popup)
    try {
      if (!this.$parent.$parent) document.getElementsByClassName('main')[0].classList.add('main--padding');
    } catch (e) {}
  },
  beforeDestroy() {
    // Temp hack for main padding, only when in page mode (not popup)
    try {
      if (!this.$parent.$parent) document.getElementsByClassName('main')[0].classList.remove('main--padding');
    } catch (e) {}
  },
};
</script>
